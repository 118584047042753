/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* navigation-large.less 2013-1-16 *******/
.cb-toggle {
  display: none;
}
.navigation {
  position: static;
  display: block;
  width: 100%;
}
.navi {
  position: static;
  background: none;
}
.menu {
  border: 0;
  font-size: 16px;
  font-size: 1.6rem;
  color: #6b6b6b;
}
/* LEVEL 1 */
div.sub1 {
  float: right;
  width: auto;
  left: 0 !important;
  margin: 54px 0.4em 0 0;
  font-size: 20px;
  font-size: 2rem;
}
.sub1 > .item {
  width: auto;
  white-space: nowrap;
  position: relative;
}
div.sub1 .menu {
  padding-left: 0.6em;
  padding-right: 0.6em;
  background: none !important;
  border-bottom: 4px solid #fff;
  font-size: 20px;
  font-size: 2rem;
}
div.sub1 .path,
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #005da8;
}
div.sub1 a.path {
  font-weight: bold;
}
/* LEVEL 2 */
div.sub2 {
  position: absolute;
  top: 98.2%;
  left: 0;
  min-width: 196px;
  width: auto;
  background: rgba(0, 93, 168, 0.84);
  top: -2000px;
  box-shadow: 0 2px 2px rgba(117, 193, 255, 0.3), 1px 0 6px rgba(0, 93, 168, 0.2);
  display: flex;
  flex-direction: column;
}
div.sub2 > .item {
  width: auto;
}
.exit > .sub2 {
  right: 0;
  left: auto;
}
.item:hover > .sub2 {
  top: 98.3%;
}
div.sub2 .menu {
  display: block;
  padding-top: 0.12em;
  padding-bottom: 0.12em;
  color: #fff !important;
  border: 0;
  line-height: 1.2;
  font-size: 17px !important;
  font-size: 1.7rem !important;
  padding-right: 0.70588235em;
  padding-left: 0.70588235em;
  hyphens: auto;
}
.sub2 > .init > .menu {
  margin-top: 0.6em;
}
.sub2 > .exit > .menu {
  margin-bottom: 0.6em;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  text-decoration: underline;
}
div.sub2:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 10px;
  content: '';
  /*background-color: #000000;*/
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  background-image: linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
}
#find {
  top: 19px;
  right: 18px;
  position: absolute;
  display: block;
  width: 156px;
  border: 1px solid #818181;
  border-radius: 1em;
  height: 22px;
}
#find #searchfield {
  top: -1px;
  left: 8px;
  border: 0;
  padding: 0;
  width: 120px;
  background: none;
  line-height: 1.2;
  position: absolute;
  box-shadow: none !important;
}
#find .find {
  position: absolute;
  right: 7px;
  top: 2px;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  width: 95.91836735%;
  max-width: 940px;
}
h1 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
}
.cb-layout2 h1 {
  line-height: 1.3;
}
.banner-fix {
  padding-bottom: 0;
}
.banner {
  position: relative;
}
.home {
  margin-top: 63px;
  font-size: 28px;
  font-size: 2.8rem;
  background-size: auto 100%;
}
#expo {
  margin-top: 6.9em;
}
.content {
  margin-top: 28px;
  padding-bottom: 43px;
}
.cb-layout2 .maincontent {
  float: left;
  width: 65.95744681%;
}
#expo {
  border-top: 0;
}
#expo:before {
  display: none;
}
#expo:after {
  height: 3.55263158%;
  background-image: url(/images/slides-after.png);
}
.footer:after {
  width: 20%;
}
.footer .vcard {
  float: left;
  width: 15.8em;
}
.sep1,
.sep2,
.sep3,
.credits {
  display: inline;
}
.copyright br {
  display: none;
}
.main {
  width: 100%;
}
.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main h2,
.main .foot {
  margin-right: 1.04166667%;
  margin-left: 1.04166667%;
}
.main .part,
.main > .grid table {
  margin-right: 1.04166667%;
  margin-left: 1.04166667%;
  width: 97.91666667%;
}
.main .tiny {
  width: 31.25%;
}
.main > .slim {
  width: 33.33333333%;
}
.main > .slim h2,
.main > .slim .foot,
.main > .slim .part,
.main > .slim.grid table {
  margin-right: 3.125%;
  margin-left: 3.125%;
}
.main > .slim .part,
.main > .slim.grid table {
  width: 93.75%;
}
.main > .slim .tiny {
  width: 93.75%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 1.5625%;
  margin-left: 1.5625%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 1.5625%;
  margin-left: 1.5625%;
  width: 96.875%;
}
.cb-layout2 .main > .slim {
  width: 50%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 3.125%;
  margin-left: 3.125%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 93.75%;
}
.cb-layout2 .main > .slim .tiny {
  width: 43.75%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.base .part,
.base > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 100%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.cb-layout1 .main {
  margin-left: -1.06382979%;
  width: 102.12765957% !important;
}
.cb-layout2 .main {
  margin-left: -1.61290323%;
  width: 103.22580645%;
}
.base {
  float: right;
  width: 31.91489362%;
}
.unit {
  line-height: 1.53846154;
}
.unit h2,
h3 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
}
.main > .wide {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
.cb-layout2 .unit h2,
.cb-layout2 h3 {
  line-height: 1.3;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */